import React from "react";
import "./App.css";
import InfoStrip from "./components/InfoStrip";

function App() {
  return (
    <div className="App">
      <header id="header" className="header">
        <a
          id="logo"
          href="https://www.penginusol.com"
          rel="noopener noreferrer"
        >
          <img src="PengInueMainLogo.png" alt="Peng Inu" />
        </a>
        <nav id="nav">
          <ul id="menu">
            <li>
              <a
                id="social"
                href="https://twitter.com/penginusol"
                title="X (Previously Twitter)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="xwhite.png" alt="twitter" />
              </a>
            </li>
            <li>
              <a
                id="social"
                href="https://t.me/penginusol"
                title="Telegram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="tg.png" alt="twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=HyWE5pKh6q5PZnsLN1UvNKKW8UgezE8sAPmGSWeNjnA6" // replace with your buy link
                title="Buy Now"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-button" // add a class for styling
              >
                BUY NOW
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <main className="main">
        <div className="hero-image-container">
          <img src="PengInu_Logo.png" alt="Peng Inu" />
        </div>
        <div className="hero-heading-container">
          <h3>TOTAL SUPPLY: 100,000,000</h3>
        </div>
        <InfoStrip />
      </main>
      <main className="Section1">
        <div className="Section1-heading-container">
          <h1>Peng Inu Has Come to Solana!</h1>
        </div>
        <div className="Section1-image-container">
          <img src="penginu_2.jpg" alt="Peng Inu" />
        </div>
      </main>
      <main className="Section2">
        <div className="Section2-heading-container">
          <h1>I'M PENG'S LITTLE COUSIN</h1>
        </div>
        <div className="Section2-image-container">
          <img src="1500x500penginu.jpeg" alt="Peng Inu" />
        </div>
      </main>
    </div>
  );
}

export default App;
