// InfoStrip.js
import React, { useState } from "react";

const InfoStrip = () => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      "HyWE5pKh6q5PZnsLN1UvNKKW8UgezE8sAPmGSWeNjnA6"
    );

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className="info-strip">
      <div className="contract-info">
        <span className="contract-wrapper">
          <p className="contract">
            HyWE5pKh6q5PZnsLN1UvNKKW8UgezE8sAPmGSWeNjnA6
          </p>
        </span>
        <img
          src="copy.png"
          onClick={copyToClipboard}
          alt="copy icon"
          className="copy-icon"
        />
        <span className="main-separator">|</span>
      </div>
      {isCopied && <div className="toast">copied</div>}
      <div className="buy-links">
        <a
          className="link"
          href="https://birdeye.so/token/HyWE5pKh6q5PZnsLN1UvNKKW8UgezE8sAPmGSWeNjnA6?chain=solana"
          target="_blank"
          rel="noopener noreferrer"
        >
          BIRDEYE
        </a>{" "}
        <span className="separator">|</span>
        <a
          className="link"
          href="https://dexscreener.com/solana/HyWE5pKh6q5PZnsLN1UvNKKW8UgezE8sAPmGSWeNjnA6"
          target="_blank"
          rel="noopener noreferrer"
        >
          DEXSCREENER
        </a>
      </div>
    </div>
  );
};

export default InfoStrip;
